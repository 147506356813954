<template>
  <div class="ZddDetail">
    <div class="info-form">
      <div class="userInfo">
        <div class="info">
          <div class="header"></div>
          <div class="detail">
            <div class="name">{{ detail.name }}</div>
            <div class="tel">{{ detail.telephone }}</div>
          </div>
        </div>
        <div class="authorization" v-if="!userId">
          <van-button type="primary" @click="handleToSharePage"
            ><SvgIcon
              type="zdd_share_auth"
              class="icon"
            />授权查看档案</van-button
          >
        </div>
      </div>
      <ul class="performance">
        <ZddCertificateItem
          v-for="certificate in detail.certListInfoResp.list"
          :key="certificate.id"
          :item="certificate"
        />
      </ul>
    </div>
    <div class="list-form">
      <div class="title">
        全部记录
        <span>{{ detail.recordList.length }}</span>
      </div>
      <van-steps
        v-if="detail.recordList.length"
        direction="vertical"
        :active="0"
        active-color="#919191"
        inactive-color="#919191"
      >
        <van-step v-for="(record, index) in detail.recordList" :key="index">
          <template v-slot:active-icon>
            <div class="active-icon"></div>
          </template>
          <template v-slot:inactive-icon>
            <div class="inactive-icon"></div>
          </template>
          <div class="step-form">
            <div class="com-info">
              <span class="name">{{ record.comName }}</span>
              <span class="desc">{{ record.industryName }}</span>
            </div>
            <div class="user-info">
              <span class="job" v-if="record.position">{{
                record.position
              }}</span>
              <span class="time">{{ record.jobTime }}</span>
            </div>
            <ul class="list" v-if="record.certList.length">
              <li
                class="item"
                v-for="cert in record.certList"
                :key="cert.businessId"
              >
                <div class="item-info">
                  <div class="label">{{ cert.fileName }}</div>
                  <div class="time">颁发时间：{{ cert.createTime }}</div>
                </div>

                <div class="item-operate">
                  <van-button
                    type="primary"
                    plain
                    size="small"
                    @click="handleToCertificateDetail(cert)"
                    >查看证书</van-button
                  >
                </div>
              </li>
            </ul>
            <div class="list-no-data" v-else>
              <SvgIcon class="list-no-data-img" type="no_certificate_data" />
              <div class="list-no-data-text">未获得证书</div>
            </div>
          </div>
        </van-step>
      </van-steps>
      <div v-else class="no-record-list">
        <SvgIcon class="no-record-list-img" type="zdd_no_record_list" />
        <div class="no-record-list-img-text">暂无记录</div>
      </div>
    </div>
    <FilePreview
      v-if="showPreview"
      append-to-body
      v-model="showPreview"
      :file="file"
    />
  </div>
</template>

<script>
  import certApis from '@/api/core/cert'
  import { authShare } from '@/utils/zdd'
  import FilePreview from '@/views/signFile/Components/FilePreview.vue'

  import noCertificateDataImg from '@/assets/icons/svg/no_certificate_data.svg'
  import ZddCertificateItem from '@/components/Zdd/ZddCertificateItem.vue'

  export default {
    name: 'ZddDetail',
    components: {
      ZddCertificateItem,
      FilePreview,
    },
    data() {
      this.noCertificateDataImg = noCertificateDataImg
      return {
        userId: undefined,
        detail: {
          name: undefined,
          idCardNo: undefined,
          telephone: undefined,
          certListInfoResp: {
            issuedCardNum: 0,
            list: [],
            waitSignCertNum: 0,
          },
          recordList: [],
        },
        showPreview: false,
        file: null,
      }
    },
    computed: {
      userInfo() {
        return this.$store.getters.getUserInfo
      },
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        const { userId } = this.$route.query
        this.userId = userId || this.userInfo.userId
        this.fetchData()
      },
      fetchData() {
        this.getArchivesDetails()
      },

      async getArchivesDetails() {
        let loadingInstance
        try {
          loadingInstance = this.$toast.loading({
            message: '加载中',
            loadingType: 'spinner',
          })
          const model = { userId: this.userId }
          this.detail = await certApis.getArchivesDetails(model)
          console.log(this.detail)
        } catch (error) {
          console.error(error)
        } finally {
          if (loadingInstance) {
            loadingInstance.close()
          }
        }
      },
      handleToCertificateDetail(cert) {
        this.file = {
          fileId: cert.fileId,
          fileName: cert.certName,
          imageList: [cert.fileUrl],
        }
        this.showPreview = true
      },
      handleToSharePage() {
        authShare()
      },
    },
  }
</script>

<style scoped lang="less">
  .ZddDetail {
    background: #f3f3f3;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .info-form {
      background: #fff;
      padding: 16px;
      margin-bottom: 10px;
      .userInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        .info {
          display: flex;
          align-items: center;
          .header {
            width: 36px;
            height: 36px;
            background-color: #f2f2f2;
            border-radius: 50%;
            margin-right: 6px;
          }
          .detail {
            .name {
              font-size: 13px;
              font-weight: 500;
              color: #4b505f;
              line-height: 12px;
              margin-bottom: 8px;
            }
            .tel {
              font-size: 13px;
              font-weight: 400;
              color: #4b505f;
              line-height: 12px;
            }
          }
        }
        .authorization {
          display: flex;
          align-items: center;
          .van-button {
            height: 32px;
            opacity: 1;
            background: #1676ff;
            border-radius: 4px;
            .van-button__text {
              display: flex;
              align-items: center;
              .icon {
                line-height: 1;
                margin-right: 5px;
              }
            }
          }
        }
      }
      .performance {
        .zdd-certificate-item + .zdd-certificate-item {
          margin-top: 14px;
        }
      }
    }
    .list-form {
      flex: 1;
      background: #fff;
      padding: 16px;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 17px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #262626;
        line-height: 24px;
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        &:before {
          display: flex;
          content: '';
          width: 3px;
          height: 16px;
          opacity: 1;
          background: #1676ff;
          margin-right: 8px;
        }
        span {
          font-size: 17px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #4b505f;
          line-height: 24px;
          margin-left: 7px;
        }
      }
      .van-steps {
        .van-step {
          &::after {
            display: none;
          }
          /deep/ .van-step__line {
            background: transparent !important;
            border-left: 1px dashed #dadada;
            height: 95%;
            top: 25px;
          }
        }

        .active-icon {
          width: 10px;
          height: 10px;
          background: rgba(22, 118, 255, 0.2);
          border-radius: 50%;
          position: relative;
          &:after {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            content: '';
            width: 5px;
            height: 5px;
            background: var(--main-color);
            border-radius: 50%;
          }
        }
        .inactive-icon {
          width: 5px;
          height: 5px;
          background: #919191;
          border-radius: 50%;
        }
        .step-form {
          .com-info {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            .name {
              font-size: 15px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: bold;
              text-align: left;
              color: #3d3d3d;
              line-height: 21px;
              margin-right: 15px;
            }
            .desc {
              font-size: 13px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              color: #6d6d6d;
              line-height: 18px;
            }
          }
          .user-info {
            display: flex;
            align-items: center;
            margin-bottom: 14px;
            .span {
              font-size: 13px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              color: #363636;
              line-height: 18px;
            }
            .job {
              margin-right: 16px;
            }
          }
          .list {
            .item {
              width: 100%;
              opacity: 1;
              border: 1px solid #dadada;
              border-radius: 4px;
              padding: 8px 16px;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              .item-info {
                flex: 1;
                .label {
                  font-size: 14px;
                  font-family: PingFangSC, PingFangSC-Medium;
                  font-weight: bold;
                  text-align: left;
                  color: #292929;
                  line-height: 20px;
                  margin-bottom: 5px;
                }
                .time {
                  font-size: 12px;
                  font-family: PingFangSC, PingFangSC-Regular;
                  font-weight: 400;
                  text-align: left;
                  color: #363636;
                  line-height: 17px;
                }
              }
            }
            .item + .item {
              margin-top: 10px;
            }
          }
          .list-no-data {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 24px 0;
            .list-no-data-img {
              width: 159px;
              height: 100%;
              line-height: 1;
              display: flex;
              /deep/ svg {
                width: 159px;
                height: 63px;
              }
            }
            .list-no-data-text {
              margin-top: 10px;
            }
          }
        }
      }
      .no-record-list {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: 1;
        justify-content: center;
        align-items: center;
        .no-record-list-img {
          width: 160px;
          height: 81px;
          display: flex;
          /deep/ svg {
            width: 160px;
            height: 81px;
          }
        }
        .no-record-list-img-text {
          margin-top: 10px;
          font-size: 13px;
          font-weight: 400;
          color: #999ea7;
          line-height: 18px;
        }
      }
    }
  }
</style>
